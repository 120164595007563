<template>
  <el-main class="sampleCase-container">
    <router-view />
  </el-main>
</template>

<script>

export default {
  name: 'SampleCase'
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.sampleCase-container {
  color: $text_dark;
  font-size: 1rem;
}
</style>
